<template>
    <el-container>
        <el-main>
            <el-form ref="addForm"  :model="formData" :rules="rules" label-width="120px" label-position="left">
                <el-form-item label="参赛人员名称" prop="linkman">
                    <el-input v-model="formData.linkman" class="inner-input" placeholder="请输入参赛人员名称"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="contactNumber">
                    <el-input v-model="formData.contactNumber" class="inner-input" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="团队名称" prop="teamName">
                    <el-input v-model="formData.teamName" class="inner-input" placeholder="请输入团队名称"></el-input>
                </el-form-item>
                <el-form-item label="团队成员" prop="members">
                    <el-input v-model="formData.members" class="inner-input" placeholder="请输入团队成员，并用空格分隔"></el-input>
                </el-form-item>
                <el-form-item prop="teamLogo" label="团队logo">
                    <el-upload
                            class="avatar-uploader"
                            accept="image/gif,image/jpeg,image/png"
                            :action="''"
                            :show-file-list="false"
                            :http-request="uploadImage"
                    >
                        <img v-if="formData.teamLogo" :src="formData.teamLogo" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="handleSubmit" style="margin-left: 10px">确 定</el-button>
                    <el-button @click="handleClose">取 消</el-button>
                </el-form-item>

            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import {ploadFile} from "@/api";
import request from '@/utils/request'
export default {
    name: "addGameActives",
    data() {
        return {
            loading:false,
            formData: {
                linkman: '',//参赛人员名称
                contactNumber: '',//联系电话
                teamName: '',//团队名称
                members: '',//团队成员
                teamLogo: '',//团队logo
            },
            rules: {
                linkman: [
                    {required: true, message: '请输入参赛人员名称！', trigger: 'change'},
                ],
                contactNumber: [
                    {required: true, message: '请输入联系电话！', trigger: 'change'},
                    {pattern: /^1[0-9]{10}$/, message: '请输入正确联系电话！', trigger: 'change'},
                ],
                teamName: [
                    {required: true, message: '请输入团队名称！', trigger: 'change'},
                ]
            },
        }
    },
    methods:{
        async uploadImage({file}) {
            try {
                // 根据后台需求数据格式
                const formData = new FormData()
                // 文件对象
                formData.append('filedata', file)
                let {data} = await ploadFile(formData)
                this.formData.teamLogo = data.url
                console.log(data)

            } catch (e) {

            }
        },
        handleSubmit(){
            this.$refs["addForm"].validate(async valid => {
                if(valid){
                   try {
                       this.loading = true
                       let formData = this.formData;
                       await request({
                           url:'platform/activity/game/addDo',
                           method:'post',
                           data:formData
                       })
                       this.loading = false;
                       this.$emit('success')
                       this.$message({
                           type: "success",
                           message: "新增成功!"
                       });
                   }catch (e) {
                       this.loading = false;
                   }
                }
            })
        },
        handleClose(){
            this.$emit('cancel')
        }
    }
}
</script>

<style scoped lang="less">
.avatar-uploader {
    display: inline-block;
    width: auto;

    /deep/ .el-upload {
        width: 146px;
        height: 146px;
        line-height: 146px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
        border: 1px dashed #dedede;
        border-radius: 10px;
    }

    .avatar {
        width: 146px;
        height: 146px;
        display: block;
    }
}
</style>
